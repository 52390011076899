import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/Layout"
import PageCarousel from "../components/PageCarousel"
import { useStaticQuery, graphql } from "gatsby"

export default function OtherMachinery() {
  const imageData = useStaticQuery(graphql`
    query {
      allFile(
        filter: {
          extension: { regex: "/(jpg)|(png)|(jpeg)/" }
          relativeDirectory: { eq: "images/other-machinery" }
        }
      ) {
        edges {
          node {
            base
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  return (
    <>
      <Helmet>
        <title>Other machinery service and repair | Service Center</title>
        <meta
          name="description"
          content="We service and repair almost anything with a small petrol engine. For example: leaf blowers, hedge trimmers, shredders, log splitters."
        />
      </Helmet>

      <Layout title="Other Machinery">
        <section className="overflow-hidden">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div>
                  <p>
                    Have you got a machine we haven’t listed on our website? Not
                    to worry, we service and repair almost anything with a small
                    petrol engine. For example:
                  </p>
                  <ul>
                    <li>leaf blowers</li>
                    <li>hedge trimmers</li>
                    <li>shredders</li>
                    <li>log splitters</li>
                  </ul>
                  <p>
                    <a href="tel:063706926">Call us</a> to speak with an expert
                    mechanic with over 25 years of experience.
                  </p>
                </div>
              </div>

              <div className="col-lg-6">
                <PageCarousel imageData={imageData} />
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  )
}
